<template>
  <div>
    <div class="loader" v-if="loading">
      <base-loader-dual-ring />
    </div>
    <div class="receipts" v-if="invoices.length > 0 && !loading">
      <div class="receipts-title" style="font-weight: 600;">Your Invoices</div>

      <div class="receipts-table">
        <table class="table">
          <thead>
          <tr>
            <th>Date</th>
            <th>Price</th>
            <th>Invoice Link</th>
            <th>Receipt Link</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="invoice in invoices" :key="invoice.id" v-show="invoice.total > 0">
            <td>{{ invoice.created | day }}</td>
            <td>${{ invoice.total }}</td>
            <td>
              <a class="BaseButton normal primary is-outlined" style="width:166px" :href="invoice.invoice_pdf">Download Invoice</a>
            </td>
            <td>
              <a class="BaseButton normal primary is-outlined" style="width:166px" :href="invoice.receipt_pdf">Download Receipt</a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="pagination-wrapper">
      <div class="pagination">
        <paginate
          :page-count="totalPages"
          :prev-text="'‹'"
          :next-text="'›'"
          class="mr-5"
          :container-class="'pagination-container'"
          :page-class="'page-item'"
          :active-class="'page-active'"
          prev-link-class="page-arrow"
          next-link-class="page-arrow"
          v-model="page"
        />
      </div>
    </div>

  </div>
</template>
<script>
import Paginate from 'vuejs-paginate'
import subscriptionsApi from '@/api/subscriptionsApi'
import BaseLoaderDualRing from '@/components/Base/BaseLoaderDualRing.vue'

export default {
  components: {
    BaseLoaderDualRing,
    Paginate
  },

  data () {
    return {
      invoices: [],
      loading: false,
      total: 1,
      page: 1,
      pagination: {
        skip: 0,
        take: 10
      }
    }
  },

  created () {
    this.getInvoices()
  },

  methods: {
    async getInvoices () {
      this.loading = true
      try {
        const response = await subscriptionsApi.getInvoices(this.pagination)
        this.total = response.data.meta.total
        this.invoices = response.data.data
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    }
  },
  computed: {
    ...mapState('authentication', [
      'user'
    ]),

    totalPages () {
      return Math.ceil(this.total / this.pagination.take)
    }
  },

  watch: {
    page () {
      this.pagination.skip = (this.page - 1) * this.pagination.take
      this.getInvoices()
    }
  }
}
</script>
<style>
/* Pagination wrapper centered within .card-body */
.pagination-wrapper {
  display: flex;
  justify-content: center; /* Center pagination horizontally */
  width: 100%; /* Ensure wrapper spans full width of card-body */
  margin: 0; /* No margins */
  padding: 0; /* No padding */
}

/* Pagination container */
.pagination {
  display: flex;
  justify-content: center; /* Center the pagination items inside the container */
  align-items: center; /* Center the items vertically */
  width: auto; /* Let it take only as much space as needed */
  margin-left: 30px; /* Center the pagination itself horizontally */
  padding: 0; /* Reset any padding */
}

/* Pagination item styles */
.pagination-container {
  display: flex;
  justify-content: center; /* Ensure items are centered horizontally */
  list-style: none;
  margin: 0;
  padding: 0;
  width: auto; /* Let it take only as much space as needed */
}

.pagination-container li {
  margin: 0 5px; /* Horizontal space between pagination items */
  border-radius: 50%;
  cursor: pointer;
}

.pagination-container li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #333;
}

.page-arrow {
  color: #333333;
  opacity: 0.5;
  font-size: 20px;
}

.page-active {
  background-color: rgba(0, 0, 0, 0.1) !important;
  cursor: default;
  pointer-events: none;
}

.page-active a {
  color: #4F4F4F;
}

</style>
